import logo from "@/images/brands/logo.png";
import axios from "axios";
import { useRouter } from "next/compat/router";
import Image from "next/image";
import Link from "next/link";
import { Fragment, useEffect, useState } from "react";
import MyIcon from "../MyIcon/MyIcon";
export default function Footer({ summary, services, contacts, subInfo }) {
  const router = useRouter();
  const { locale } = router;
  // console.log("summary", summary);

  const [footers, setFooters] = useState();
  useEffect(() => {
    const getFooters = async () => {
      const response = await axios.get(
        `${baseApiUrl}/api/footer?populate=LeftRow.image&populate=MiddleRow&populate=RightRow&locale=${locale}`
      );
      setFooters(response.data.data);
    };
    getFooters();
  }, [locale]);
  return (
    <Fragment>
      <div className="tw-bg-_black-700 dark:tw-bg-_black-800" id="footer">
        <div
          className="container tw-grid tw-grid-rows-[auto_auto] tw-gap-y-7 tw-pt-[30px] sm:tw-pt-[40px] md:tw-pt-[50px] lg:tw-pt-[60px] tw-text-_white"
        >
          {/* Row 1 */}
          <div className="tw-grid tw-grid-cols-1 tw-gap-7 lg:tw-grid-cols-3">
            <div
              className="tw-flex tw-flex-col tw-items-start lg:tw-w-[fit-content]"
              id="footer-summary"
            >
              <Image
                src={logo}
                alt="logo"
                className="tw-mb-[35px]"
                height={30}
                overrideSrc="Thạch Anh ITT Logo"
                quality={90}
              />
              <p
                className="tw-mb-[30px] tw-whitespace-break-spaces tw-text-body-base tw-font-light tw-text-_white
                           lg:tw-text-body-lg"
              >
                {summary[locale][0].description}
              </p>
              <div className="tw-flex tw-flex-wrap tw-gap-[10px]">
                {(summary.socialLinks || []).map((socialLink, index) => (
                  <Link
                    href={socialLink.link}
                    key={index}
                    className="tw-rounded-[50%] tw-transition-all hover:tw-bg-red"
                    aria-label={`${socialLink.iconName} icon`}
                  >
                    <MyIcon
                      iconName={socialLink.iconName}
                      className={{
                        background: [
                          `tw-rounded-[50%] tw-border-[1px] tw-border-solid tw-border-gray-200 tw-bg-transparent
                                       tw-opacity-10`,
                        ],
                      }}
                    />
                  </Link>
                ))}
              </div>
            </div>
            <div
              className="tw-flex tw-flex-col tw-items-start"
              id="footer-service"
            >
              <p className="tw-text-h3-base tw-text-_white lg:tw-text-h3-lg">
                {locale === "vi" ? "Dịch Vụ" : "Service"}
              </p>
              <ul className="tw-mt-[35px] tw-flex tw-flex-col tw-gap-[15px] tw-opacity-80">
                {services?.[locale].map((service, index) => {
                  return (
                    <li
                      key={index}
                      className="tw-flex tw-items-center tw-gap-[10px]"
                    >
                      <MyIcon
                        iconName={"ri:arrow-right-double-fill"}
                        hasBackground={false}
                        className={{ background: "tw-px-0 tw-py-0" }}
                      />
                      <p className="tw-whitespace-break-spaces tw-text-body-base tw-text-_white lg:tw-text-body-lg">
                        {service}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className="tw-flex tw-flex-col tw-items-start"
              id="footer-contact"
            >
              <p className="tw-text-h3-base tw-text-_white lg:tw-text-h3-lg">
                {locale === "vi" ? "Liên Hệ" : "Contact"}
              </p>
              <ul className="tw-mt-[35px] tw-flex tw-flex-col tw-gap-[22px]">
                {contacts?.[locale].map((contact, index) => {
                  return (
                    <li
                      key={index}
                      className="tw-flex tw-items-start tw-content-center tw-gap-[20px]"
                    >
                      <MyIcon
                        iconName={contact.iconName}
                        className="tw-flex-shrink-0"
                      />
                      <div className="tw-flex-1 tw-min-w-0">
                        <p className="tw-text-h4-base tw-capitalize tw-py-2 tw-text-_white lg:tw-text-h4-lg">
                          {contact.title}
                        </p>
                        <p className="tw-text-body-base tw-text-_white tw-opacity-80 lg:tw-text-body-lg tw-break-words">
                          {contact.value}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {/* Row 2 */}
        </div>
      </div>
      <div className="tw-bg-black-900">
        <div className="container">
          <div
            id="footer-sub-info"
            className="tw-flex tw-h-[fit-content] tw-flex-col tw-gap-y-5 tw-py-[30px] tw-text-body-base
                     tw-opacity-80 lg:tw-flex-row lg:tw-justify-between lg:tw-gap-0 lg:tw-text-body-lg"
          >
            <p className="tw-text-white">
              © {subInfo.siteName} 2024 | All Rights Reserved
            </p>
            <div className="tw-flex tw-gap-x-[10px] sm:tw-gap-x-[18px] md:tw-gap-x-[26px] lg:tw-gap-x-[34px]">
              {subInfo.subLinks[locale].map((subLink, index) => (
                <Link className="tw-text-white" href={subLink.link} key={index}>
                  {subLink.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Footer.defaultProps = {
  summary: {
    vi: [
      {
        description:
          "Công Ty Cổ Phần Công Nghệ Thông Tin Viễn Thông Thạch Anh là nhà cung cấp giải pháp hàng đầu trong lĩnh vực công nghệ thông tin, viễn thông như hệ thống camera quan sát, an ninh, hệ thống báo cháy, báo trộm, hệ thống tổng đài nội bộ, hệ thống nhà thông minh MobiEyes SmartHome, giải pháp mạng, VPN, Voice IP...",
      },
    ],
    en: [
      {
        description:
          "Thach Anh Information Technology and Telecommunications Joint Stock Company is a leading provider of solutions in the fields of information technology and telecommunications, including services such as surveillance camera systems, security systems, fire alarm systems, burglar alarm systems, internal PBX systems, MobiEyes SmartHome solutions, network solutions, VPN, and Voice IP...",
      },
    ],
    socialLinks: [
      {
        link: "https://www.facebook.com/mobieyes",
        iconName: "ri:facebook-fill",
      },
      {
        link: "#",
        iconName: "ri:twitter-fill",
      },
      {
        link: "#",
        iconName: "ri:instagram-fill",
      },
      {
        link: "#",
        iconName: "mingcute:pinterest-fill",
      },
    ],
  },
  services: {
    vi: [
      "Giải pháp Chuyển đổi Số",
      "Phát triển Phần mềm Tùy chỉnh",
      "Giải pháp Hạ tầng Mạng",
      "Phân tích Dữ liệu và Thông tin Chi tiết",
      "Dịch vụ Quản lý Dự án CNTT",
    ],
    en: [
      "Digital Transformation Solutions",
      "Custom Software Development",
      "Network Infrastructure Solutions",
      "Data Analytics and Insights",
      "IT Project Management Services",
    ],
  },
  contacts: {
    vi: [
      {
        title: "Số Điện Thoại",
        value: "1900 299968",
        iconName: "ri:phone-fill",
      },
      {
        title: "Địa Chỉ",
        value: "504/58A Kinh Dương Vương, An Lạc A, Bình Tân, TP.HCM",
        iconName: "mdi:location",
      },
      {
        title: "Email",
        value: "info@thachanhitt.vn",
        iconName: "material-symbols:mail",
      },
    ],
    en: [
      {
        title: "Phone Number",
        value: "1900 299968",
        iconName: "ri:phone-fill",
      },
      {
        title: "Address",
        value:
          "504/58A Kinh Duong Vuong, An Lac A Ward, Binh Tan District, Ho Chi Minh City",
        iconName: "mdi:location",
      },
      {
        title: "Email",
        value: "info@thachanhitt.vn",
        iconName: "material-symbols:mail",
      },
    ],
  },
  subInfo: {
    siteName: "Thạch Anh ITT",
    subLinks: {
      vi: [
        {
          title: "Điều khoản & Điều kiện",
          link: "/",
        },
        {
          title: "Chính sách Bảo mật",
          link: "/",
        },
        {
          title: "Liên hệ",
          link: "/contact",
        },
      ],
      en: [
        {
          title: "Terms & Condition",
          link: "/",
        },
        {
          title: "Privacy Policy",
          link: "/",
        },
        {
          title: "Contact Us",
          link: "/en/contact",
        },
      ],
    },
  },
};
